body.is-preload #banner .logo {
  opacity: 1;
  transform: none;
}

body.is-preload #banner h2, body.is-preload #banner p {
  opacity: 1;
  -moz-filter: none;
  filter: none;
  -ms-filter: none;
  transform: none;
}

/*# sourceMappingURL=index.eaee1e74.css.map */
