/*
	Solid State by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Banner */

	body.is-preload #banner .logo {
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		opacity: 1;
	}

	body.is-preload #banner h2 {
		opacity: 1;
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		-moz-filter: none;
		-webkit-filter: none;
		-ms-filter: none;
		filter: none;
	}

	body.is-preload #banner p {
		opacity: 01;
		-moz-transform: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		-moz-filter: none;
		-webkit-filter: none;
		-ms-filter: none;
		filter: none;
	}